//@ts-nocheck
import React, { ReactElement } from "react";

import { User as FirebaseUser } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

import {
  Authenticator,
  buildCollection,
  buildEntityCallbacks,
  buildProperty,
  buildSchema,
  EntityCustomView,
  EntityOnDeleteProps,
  FieldProps,
  useInitialiseFirebase,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps,
} from "@camberi/firecms";
import types from "./data/types.json";
import "typeface-rubik";
import "typeface-space-mono";
import { Typography } from "@mui/material";
const firebaseConfig = {
  apiKey: "AIzaSyCGWLKJSs_npStV3zaybu5BQ2L1EIcL5qA",
  authDomain: "lnfound-146b3.firebaseapp.com",
  projectId: "lnfound-146b3",
  storageBucket: "lnfound-146b3.appspot.com",
  messagingSenderId: "477972900441",
  appId: "1:477972900441:web:21de4868229915deae5f48",
};
// import { initializeApp } from "firebase/app";
// export const Firebase = initializeApp(firebaseConfig);

// console.log("getFunctions", getFunctions);
// const functions = getFunctions(firebaseConfig, "us-central");

const locales = {
  "en-US": "English (United States)",
  "fr-FR": "French (France)",
  "de-DE": "German",
};

type LNObject = {
  name: string;
  code: string;
  description: string;
  hasLost: boolean;
  photo: string;
  showCity: boolean;
  showName: boolean;
  type: number;
  userId: string;
};

type LNUser = {
  firstName: string;
  lastName: string;
  pseudo: string;
  email: string;
  city: string;
  createdAt: Date;
};

interface CustomColorTextFieldProps {
  type: number;
}
const userSchema = buildSchema<LNUser>({
  name: "Object",
  properties: {
    firstName: {
      title: "first name",
      validation: { required: true },
      dataType: "string",
    },
    lastName: {
      title: "last name",
      validation: { required: true },
      dataType: "string",
    },

    pseudo: {
      title: "Pseudo",
      validation: { required: true },
      dataType: "string",
    },
    email: {
      title: "Email",
      validation: { required: true },
      dataType: "string",
    },
    city: {
      title: "City",
      validation: { required: true },
      dataType: "string",
    },
    createdAt: {
      title: "Date creation",
      validation: { required: true },
      dataType: "timestamp",
    },
  },
});
function CustomTypeField({ value }: any): ReactElement {
  return <span>{types[value].key}</span>;
}

const objectSchema = buildSchema<LNObject>({
  name: "Object",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
    code: {
      title: "Code",
      validation: { required: true },
      dataType: "string",
    },
    description: {
      title: "Description",
      description: "Not mandatory but it'd be awesome if you filled this up",
      longDescription:
        "Example of a long description hidden under a tooltip. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis bibendum turpis. Sed scelerisque ligula nec nisi pellentesque, eget viverra lorem facilisis. Praesent a lectus ac ipsum tincidunt posuere vitae non risus. In eu feugiat massa. Sed eu est non velit facilisis facilisis vitae eget ante. Nunc ut malesuada erat. Nullam sagittis bibendum porta. Maecenas vitae interdum sapien, ut aliquet risus. Donec aliquet, turpis finibus aliquet bibendum, tellus dui porttitor quam, quis pellentesque tellus libero non urna. Vestibulum maximus pharetra congue. Suspendisse aliquam congue quam, sed bibendum turpis. Aliquam eu enim ligula. Nam vel magna ut urna cursus sagittis. Suspendisse a nisi ac justo ornare tempor vel eu eros.",
      dataType: "string",
      columnWidth: 300,
    },
    hasLost: {
      title: "Lost ?",
      dataType: "boolean",
      disabled: true,
    },
    photo: buildProperty({
      // The `buildProperty` method is an utility function used for type checking
      title: "Image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "objects",
          acceptedFiles: ["image/*"],
        },
      },
    }),
    showCity: {
      title: "Show City",
      dataType: "boolean",
    },
    showName: {
      title: "Show Name",
      dataType: "boolean",
    },
    type: {
      title: "Type",
      dataType: "number",
      config: {
        Preview: CustomTypeField,
      },
    },
    userId: {
      title: "Owner",
      dataType: "string",
    },
  },
});

export default function App() {
  // const app = useInitialiseFirebase(firebaseConfig);
  // console.log("app", app);
  const navigation: NavigationBuilder = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    let func = getFunctions(authController.user.auth.app, "us-central1");

    const deleteAccount = httpsCallable(func, "deleteAccount");
    return {
      collections: [
        buildCollection({
          path: "users",
          schema: userSchema,
          name: "Users",
          subcollections: [
            {
              path: "objects",
              schema: objectSchema,
              name: "Objects",
              permissions: ({ authController }) => ({
                edit: true,
                create: false,
                // we have created the roles object in the navigation builder
                // delete: authController.extra.roles.includes("admin"),
                delete: true,
              }),
            },
          ],
          permissions: ({ authController }) => ({
            edit: true,
            create: false,
            // we have created the roles object in the navigation builder
            // delete: authController.extra.roles.includes("admin"),
            delete: true,
          }),
          callbacks: buildEntityCallbacks({
            onDelete: async (props: EntityOnDeleteProps<LNUser>) => {
              await deleteAccount({
                userId: props?.entityId,
              });
            },
          }),
        }),
      ],
    };
  };

  const myAuthenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController,
  }) => {
    const result = await(user as any).getIdTokenResult();
    if (result?.claims?.admin) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <FirebaseCMSApp
      name={"LNFound"}
      authentication={myAuthenticator}
      allowSkipLogin={false}
      signInOptions={["password"]}
      LoginViewProps={{
        NoUserComponent: (
          <h1>An Error has occured. Please contact your admin</h1>
        ),
        disableSignupScreen: true,
      }}
      navigation={navigation}
      firebaseConfig={firebaseConfig}
    />
  );
}
